.App{
  text-align: center;
}

.body-container{
  width: 100%;
}

body {
}

.content-for-user-action{
  background-image: url("../Assets/backgrounds/bgGeneral.png");
  align-items: center;
  align-items: center;
  height: 91vh;
  min-width: 100vw;
}


